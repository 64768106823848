<template>
  <div :class="{container:true,notScroll:isScroll}">
    <MoTitle :showLeft="false" title="博士后管理系统" @moTitltFn="navPanel"></MoTitle>
    <div class="moPayContent">
      <!--头部 -->
        <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">博士后管理系统</h1>
          <button class="showBtn"  @click="$router.push('/moShow')">立即预约演示</button>
        </div>

        <div class="contentBgColor center">
          <h2 class="h2Desc">博士后管理系统</h2>
          <span class="spanDesc"></span>
          <div class="designIdea">
           博士后管理系统是对博士后进站、出站、流动站以及在站期间的中期考核、基金项目等业务的管理。为吸引、培养和使用创新型优秀人才，建立有利于人才流动的管理系统，实现博士后进站、在站、出站全生命周期管理，促进产学研结合。
          </div>
          <el-button @click="$router.push('/moShow')" class="knowMoreBtn">了解更多</el-button>
          <img src="@/assets/product/ProductBsh/gaint.png" alt="" style="width:100%;margin:20px 0">
        </div>
        <!-- 系统架构 -->
        <div class="contentBgColor center">
          <h2 class="h2Desc">系统功能图</h2>
          <span class="spanDesc"></span>
          <img src="@/assets/product/ProductBsh/gnt.png" alt="" style="width:100%;margin:30px 0">
        </div>

        <div class="contentBgColor center">
          <h2 class="h2Desc">系统价值优势</h2>
          <span class="spanDesc"></span>
          <div class="moValueContent">
            <div v-for="(item,index) in valueList" :key="index" class="moValueItem">
              <img :src="item.src" alt="" class="moVlaueImg">
              <div class="moValueText">{{item.content}}</div>
            </div>
          </div>
        </div>

      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
export default {
  name: 'moProductBsh',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      valueList: [
        { content: '流程设计环环相扣，上传下达自动化。', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '与其他业务系统联动，实现信息互联互通。', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '梳理博士后全生命周期业务，促进博士后管理规范化。', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/product/productBsh')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #222;
    box-sizing: border-box;
}
img {
    object-fit: cover;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.clickBtn{
  box-shadow: 0 .375rem 1.25rem -0.375rem rgba(131, 7, 7, 0.4);
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.freeMinBtn{
  width: 6rem;
  height: 2rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 1.875rem;
  border: 0;
  line-height: 2rem;
  font-size: .875rem;
  color: #fff;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.pdesc {
  font-size: 1rem;
  color: #222;
  line-height: 1.5rem;
  margin-top: .625rem;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.moPayContent{
  padding-top: 2.75rem;
}
.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/product/ProductBsh/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.designIdea{
  margin: 20px 0;
  font-size: .9375rem;
line-height: 1.5rem;

}
.knowMoreBtn{
  width: 120px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 30px;
  border: 1px solid rgba(211,218,228,0.5);
  color: #444444;
  font-size: 14px;
}

.moValueContent{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.moValueItem{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(220,223,230,.5);
  box-sizing: border-box;
}
.moVlaueImg{
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.moValueText{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  margin-left: 20px;
}
</style>
